import { computed, Injectable, Signal } from '@angular/core';
import { ViewDto } from '../dtos';
import { GridColumn, ViewStateInitOptions } from '../models';

@Injectable()
export abstract class ViewState<T> {
    public abstract views: Signal<ViewDto<T>[]>;
    public abstract selectedView: Signal<ViewDto<T> | null>;
    public abstract allColumns: Signal<GridColumn<T>[]>;
    public columns = computed<GridColumn<T>[]>(() => {
        const view = this.selectedView();
        if (view == null) {
            return [];
        }

        return this.allColumns()
            .filter((column) => view.fields.includes(column.field))
            .sort((a, b) => view.fields.indexOf(a.field) - view.fields.indexOf(b.field));
    });

    public abstract init(options: ViewStateInitOptions<T>): Promise<void>;

    public abstract selectView(view: ViewDto<T>): void;
    public abstract createView(): Promise<void>;
    public abstract editView(view: ViewDto<T>): Promise<void>;
    public abstract removeView(view: ViewDto<T>): Promise<void>;
}
