import { computed, inject, Injectable, Provider, signal } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { openConfirmDialog } from '@nuis/common';
import { DialogService } from 'primeng/dynamicdialog';
import { openViewEditor } from '../components/view-editor-dialog';
import { ViewDto } from '../dtos';
import { GridColumn, ViewStateInitOptions } from '../models';
import { ViewState } from './view.state';

export function provideLocalStorageViewState(): Provider[] {
    return [LocalStorageViewState, { provide: ViewState, useClass: LocalStorageViewState }];
}

@Injectable()
class LocalStorageViewState<T> extends ViewState<T> {
    private readonly dialogService = inject(DialogService);
    private readonly translate = inject(TranslateService);

    public override views = signal<ViewDto<T>[]>([]);
    public override selectedView = signal<ViewDto<T> | null>(null);
    public override allColumns = signal<GridColumn<T>[]>([]).asReadonly();

    protected tableIdentifier = signal<string>('');
    protected viewsKey = computed<string>(() => `${this.tableIdentifier()}-views`);
    protected lastViewIdKey = computed<string>(() => `${this.tableIdentifier()}-last-view`);

    public override init(options: ViewStateInitOptions<T>): Promise<void> {
        this.tableIdentifier.set(options.tableIdentifier);

        const customViews: ViewDto<T>[] = JSON.parse(localStorage.getItem(this.viewsKey()) ?? '[]');
        this.views.set([...options.predefinedViews, ...customViews]);

        const lastViewId = localStorage.getItem(this.lastViewIdKey()) ?? null;
        const selectedView = this.views().find((view) => view.id === lastViewId) ?? options.predefinedViews[0] ?? null;
        this.selectedView.set(selectedView);

        this.allColumns = options.allColumns;

        return Promise.resolve();
    }

    public override selectView(view: ViewDto<T>) {
        this.selectedView.set(view);
        localStorage.setItem(this.lastViewIdKey(), view.id);
    }

    public override async createView(): Promise<void> {
        await openViewEditor(this.dialogService, this.translate, {
            view: null,
            allGridColumns: this.allColumns(),
            onSave: (view) => {
                const views = [...this.views(), view];
                this.saveViews(views);
                this.selectView(view);
            },
        });
    }

    public override async editView(view: ViewDto<T>): Promise<void> {
        await openViewEditor(this.dialogService, this.translate, {
            view: view,
            allGridColumns: this.allColumns(),
            onSave: (view) => {
                const index = this.views().findIndex((v) => v.id === view.id);
                if (index <= 0) {
                    return;
                }

                const views = [...this.views()];
                views[index] = view;
                this.saveViews(views);
                this.selectView(view);
            },
        });
    }

    public override async removeView(view: ViewDto<T>): Promise<void> {
        const accept = await openConfirmDialog(this.dialogService, {
            header: this.translate.instant('viewEditor.confirmation.delete.header'),
            message: this.translate.instant('viewEditor.confirmation.delete.message'),
            acceptLabel: this.translate.instant('actions.delete'),
            acceptSeverity: 'danger',
        });
        if (!accept) {
            return;
        }

        const views = this.views().filter((v) => v.id !== view.id);
        this.saveViews(views);
        this.selectView(views[0] ?? null);
    }

    private saveViews(views: ViewDto<T>[]): void {
        this.views.set(views);
        const customViews = views.filter((v) => !v.isStandardView);
        localStorage.setItem(this.viewsKey(), JSON.stringify(customViews));
    }
}
